import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Signin from './Signin';

import { init } from "ityped";

import 'semantic-ui-css/semantic.min.css'
// import { Link } from 'react-router-dom';

import 'react-responsive-modal/styles.css';
import './navbar.css';



function Navbar2() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      backDelay: 1500,
      showCursor: true,
      strings:[
        'Make an appointment',
        'with your health specialist'
      ]
    })
  }, [])
	   
	return (
    <Fragment>
      <section id="hero">
      <input type="checkbox" className="menu-btn" id="menu-btn" />
      <label for="menu-btn" className="menu-icon">
        <span className="nav-icon"></span>
      </label>
    
        <nav className="navigation">
      
      <Link to="/" className="logo"><span>See-</span>Docs</Link>
      
      <ul className="menu">
        <li><Link to="#">Find A Doctor</Link></li>
        <li><Link to="specialists">Specialists ?</Link></li>
        <li><a href="http://store.thenavigo.com/" target="_blank" rel="noopener noreferrer">Store </a></li>
      </ul>
      
      <a href="#" className="nav-appointment-btn">Appointment</a>
    </nav>

    <div className="hero-content">
      <div className="hero-text">
        <h1>
          <span ref={textRef}> </span>
        </h1>
        <p>It's smooth, quick, and secure: Everything we do is geared towards customer satisfaction.</p>

        <div className="hero-text-btns">
          <Link to="#">
            <i className="fa-solid fa-magnifying-glass"></i>
            Find Doctor
          </Link>
          <a href="#">
            <i className="fa-solid fa-check"></i>
            Book Appointment
          </a>
        </div>
      </div>
      <div className="hero-img">
        <img src={require('../../images/d4.png')} alt="" />
      </div>
    </div>

  </section>

        
    </Fragment>
		)
}

export default Navbar2;