import React, { Fragment  } from 'react';
import Navheader from '../layout/Navheader';
import Footer from '../layout/Footer';


const Faq = () => {
	return (
		<Fragment>
		<Navheader />

		<section className="accordion__section">
		<div className="title">
			<h2>Why you should choose See-Docs</h2>
			<p className="sdp">We invest in the longer term. The relationship. The content. 
			The value.</p>
		</div>

		<div className="wrapper">
			<div className="grid">
				<div className="col">
					<img src={require('../../images/download-app.png')} />
				</div>
				<fieldset id="accordion">
				    <label>
						<span>Question 1</span>
						<input type="radio" value="bar1" name="accordion" checked />
						<div><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore impedit ducimus sequi quis voluptatem id aliquid quod distinctio, maiores iste eos fugiat aliquam minima sed? Numquam tempora, quaerat illo error deserunt ad, possimus repudiandae tempore corporis eaque magnam consequuntur nisi?</p></div>
					</label>
					<label>
						<span>Question 2</span>
						<input type="radio" value="bar2" name="accordion" />
						<div><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore impedit ducimus sequi quis voluptatem id aliquid quod distinctio, maiores iste eos fugiat aliquam minima sed? Numquam tempora, quaerat illo error deserunt ad, possimus repudiandae tempore corporis eaque magnam consequuntur nisi? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore impedit ducimus sequi quis voluptatem id aliquid quod distinctio, maiores iste eos fugiat aliquam minima sed? Numquam tempora, quaerat illo error deserunt ad, possimus repudiandae tempore corporis eaque magnam consequuntur nisi?</p></div>
					</label>
					<label>
						<span>Question 3</span>
						<input type="radio" value="bar3" name="accordion" />
						<div><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore impedit ducimus sequi quis voluptatem id aliquid quod distinctio, maiores iste eos fugiat aliquam minima sed? Numquam tempora, quaerat illo error deserunt ad, possimus repudiandae tempore corporis eaque magnam consequuntur nisi?</p></div>
					</label>
				</fieldset>
			</div>
		</div>
	</section>

		<Footer />
		</Fragment>
	
	)
}


export default Faq;