import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./Testimonials.css";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div> 
          <img src={require('../../images/photo-tabs2.jpg')} />
          <div className="myCarousel">
            <h3>Shirley Anderson</h3>
            <h4>Designer</h4>
            <p>
              “Everyone was very nice, and it is nice to be treated well since you are the patient in need of help. Caring, smiling and laughing is good atmosphere for patients. I was very well taken care of.”
            </p>
          </div>
        </div>

        <div>
          <img src={require('../../images/photo-tabs5.jpg')} />
          <div className="myCarousel">
            <h3>Lihn Keystone</h3>
            <h4>Designer</h4>
            <p>
              “Everyone was very nice, and it is nice to be treated well since you are the patient in need of help. Caring, smiling and laughing is good atmosphere for patients. I was very well taken care of.”
            </p>
          </div>
        </div>

        <div>
          <img src={require('../../images/photo-tabs8.jpg')} />
          <div className="myCarousel">
            <h3>Brian Sorel</h3>
            <h4>Designer</h4>
            <p>
              “Everyone was very nice, and it is nice to be treated well since you are the patient in need of help. Caring, smiling and laughing is good atmosphere for patients. I was very well taken care of.”
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}