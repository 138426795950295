import React, { Fragment } from 'react';
import "./Register.css";
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';

import Navheader from './Navheader';
import Footer from './Footer';

const Signup = () => {
  const navigate = useNavigate()
  //form handler
  const onfinishHandler = async (values) => {
    try {
      const res = await axios.post("/api/v1/user/register", values)
      if (res.data.success) {
        message.success("Register Successfully");
        navigate('/signin');
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error)
      message.error('Something Went Wrong')
    }
  };

  return (
    <Fragment>
    <Navheader />

    <div className="form-container">
      <Form 
      layout="vertical" 
      onFinish={onfinishHandler} 
      className="register-form"
      >
        <h3>Register Form</h3>
        <Form.Item label="Name" name="name">
          <Input type="text" required />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input type="email" required />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input type="password" required />
        </Form.Item>
        <Link to="/signin" className="m-2"> Already user login here. </Link>  
        

         <Button type="primary" htmlType="submit" className="login-form-button">
          Register
        </Button>
      </Form>
    </div> 
  <Footer />
  </Fragment>
  );
};  

export default Signup;