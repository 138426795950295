import React, { Component, useState, useEffect } from 'react';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';


import './App.css';


import Landing from './components/layout/Landing';
import Doctor from './components/layout/Doctor';
import Specialist from './components/layout/Specialist';
import Signin from './components/layout/Signin';
import Signup from './components/layout/Signup';



import Privacy from "./components/register/Privacy";
import Terms from "./components/register/Terms";
import Faq from "./components/register/Faq";

function App()  {		
  	 return ( 	 		
  	 			<>
	  			<BrowserRouter>
			        <Routes>
			        	<Route exact path="/" element={<Landing />} />
			        	<Route exact path="/doctors" element={<Doctor />} />
			        	<Route exact path="/specialists" element={<Specialist />} />
			        	<Route exact path="/signin" element={<Signin />} />
			        	<Route exact path="/signup" element={<Signup />} />
			        	<Route exact path="/terms" element={<Terms />} />
			        	<Route exact path="/privacy" element={<Privacy />} />
			        	<Route exact path="/faq" element={<Faq />} />
			        </Routes>
			      <MessengerCustomerChat
			          pageId="136205017158984" 
			          appId="373601106515581"
			        />

			    </BrowserRouter>
				</>	
  	 	);
}
export default App;
