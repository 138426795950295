import React, { Fragment } from 'react';
import "./Register.css";
import { Button, Form, Input, message } from 'antd';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Navheader from './Navheader';
import Footer from './Footer';

const Signin = () => {
  const navigate = useNavigate()
  //form handler
  const onfinishHandler = async (values) => {
    try {
      const res = await axios.post("/api/v1/user/login", values);
      if(res.data.success) {
        localStorage.setItem("token", res.data.token);
        message.success("Login Successfully");
        navigate("/");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("something went wrong");
    }
  };

  return (
  <Fragment>
  <Navheader />
  
    <div className="form-container">
      <Form 
      layout="vertical" 
      onFinish={onfinishHandler} 
      className="register-form"
      >
        <h3>Login Form</h3>
        <Form.Item label="Email" name="email">
          <Input type="email" required />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input type="password" required />
        </Form.Item>
        <Link to="/signup" className="m-2"> Not a user here </Link> 
       
         <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form>
    </div>
    <Footer />
  </Fragment>
  );
};

export default Signin;