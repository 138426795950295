import React from "react";
import "./exo.css";

const Exercices = () => {
	return (
		<section>
			<div className="container12 exercices__container12">
				<div className="exercices__top">
					<h2>
						Benefits of <span className="highlights">Our Platform</span>
					</h2>
					<p>
						High-quality health care helps prevent diseases and improve quality of life. <br />
						Helping health care providers communicate more effectively can help improve health and well-being.
					</p>
				</div>

				<div className="exercices__wrapper">
					<div className="exercices__item">
						<span className="exercices__icon">
							<img src={require('../../images/extended.png')} alt="" />
						</span>

						<div className="exercices__content">
							<h4>CONTINUITY.</h4>
							<p>
								When you have an appointment, you don’t have to explain that.
							</p>
						</div>
					</div>


					<div className="exercices__item">
						<span className="exercices__icon">
							<img src={require('../../images/lunges.png')} alt="" />
						</span>

						<div className="exercices__content">
							<h4>EARLY DETECTION.</h4>
							<p>
								Regular checks improve as a preventive method.
							</p>
						</div>
					</div>


					<div className="exercices__item">
						<span className="exercices__icon">
							<img src={require('../../images/yoga-pose.png')} alt="" />
						</span>

						<div className="exercices__content">
							<h4>BETTER COMMUNICATION.</h4>
							<p>
								It’s easier to talk about sensitive issues with someone you know in a familiar setting.
							</p>
						</div>
					</div>
				</div>

			</div>
		</section>
	)
}


export default Exercices;