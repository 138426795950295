import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { Link, Redirect } from 'react-router-dom';

import Navheader from './Navheader';
import Footer from './Footer';
import Exercices from './Exercices';



class Specialist extends Component {

  constructor(props) {
      
      super(props)
        this.state = {
           fields: {
           	fullname: '', 
           	phone: '', 
           	email: '',
           	subject: '',
           	message: ''
           },
           errors: {}
       }
    }

     handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        
        if(!fields["fullname"]){
           formIsValid = false;
           errors["fullname"] = "Please fill out this field";
        }

        // if(typeof fields["fullname"] !== "undefined"){
        //    if(!fields["fullname"].match(/^[a-zA-Z]+$/)){
        //       formIsValid = false;
        //       errors["fullname"] = "Seulement les lettres";
        //    }        
        // }


           if(!fields["phone"]){
           formIsValid = false;
           errors["phone"] = "Please fill out this field";
        }

        if(typeof fields["phone"] !== "undefined"){
           if(!fields["phone"].match(/^[0-9]+$/)){
              formIsValid = false;
              errors["phone"] = "Please fill out this field";
           }        
        }

        
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Please fill out this field";
        }

        if(typeof fields["email"] !== "undefined"){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Please include an '@' in the email address";
            }
       }  

        if(!fields["subject"]){
           formIsValid = false;
           errors["subject"] = "Please fill out this field";
        }

        // if(typeof fields["subject"] !== "undefined"){
        //    if(!fields["subject"].match(/^[a-zA-Z]+$/)){
        //       formIsValid = false;
        //       errors["subject"] = "Seulement les lettres";
        //    }        
        // }


         if(!fields["message"]){
           formIsValid = false;
           errors["message"] = "Please fill out this field";
        }

        // if(typeof fields["message"] !== "undefined"){
        //    if(!fields["message"].match(/^[a-zA-Z]+$/)){
        //       formIsValid = false;
        //       errors["message"] = "Seulement les lettres";
        //    }        
        // }

       this.setState({errors: errors});
       return formIsValid;
   }

   contactSubmit(e){
        e.preventDefault();

        let fields = this.state.fields;
         if(this.handleValidation(this.state.errors)){
          axios.post('/contact/create-contact/', fields)
          .then(res => {
         this.setState({fields: {
           	fullname: '', 
           	phone: '', 
           	email: '',
           	subject: '',
           	message: ''
           }});
         })
           NotificationManager.success('Thank you for trusting us, we will get back to you as soon as possible.', 'Successful!', 2000);
        }else{
           NotificationManager.error('Error in the form!', 'erreurs');  
        }

    };



    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }



  render() {	
  return (
    <Fragment>
  	<Navheader />

  <section className="about left__titles">
        <div className="container">
          <p className="subtitle">Specialists</p>
          <h2 className="title">
            Your Success is Our Mission. <br />
          </h2>
          <div className="about__data">
            <a href="https://seedoc.substack.com" target="_blank" rel="noopener noreferrer" className="btn btn__arrow"
              >Read more
              <svg
                width="24"
                height="12"
                viewBox="0 0 24 12"
                xmlns=""
              >
                <path
                  d="M23.725 5.33639C23.7248 5.33611 23.7245 5.33578 23.7242 5.3355L18.8256 0.460497C18.4586 0.0952938 17.865 0.0966529 17.4997 0.463684C17.1345 0.830668 17.1359 1.42425 17.5028 1.7895L20.7918 5.0625H0.9375C0.419719 5.0625 0 5.48221 0 6C0 6.51778 0.419719 6.9375 0.9375 6.9375H20.7917L17.5029 10.2105C17.1359 10.5757 17.1345 11.1693 17.4998 11.5363C17.865 11.9034 18.4587 11.9047 18.8256 11.5395L23.7242 6.6645C23.7245 6.66421 23.7248 6.66389 23.7251 6.66361C24.0923 6.29714 24.0911 5.70164 23.725 5.33639Z"
                />
              </svg>
            </a>
            <div className="about__data-img">
              <img src={require('../../images/about.png')} alt="" />
              <div className="content">
                <h3 className="title">See-Docs & Thenavigo</h3>
                <p className="description">
                  Let our Agile Team understand and serve your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>      

<Exercices />


     <section className="section9 partners container9">
        <div className="text-left">
          <h2 className="highlights">Our clients</h2>
          <h2 className="title">Trusted By Brands</h2>
          <p>
            We have built a universal tool to address health care concerns 
            in Africa and around the world, Develop your activity by working more serenely.
          </p>
        </div>
        <div className="partners-container">
          <img src={require('../../images/see-docs.png')} alt="" />
          <img src={require('../../images/Tnvgok.png')} alt="" />
          <img src={require('../../images/see-docs.png')} alt="" />
          <img src={require('../../images/Tnvgok.png')} alt="" />
          <img src={require('../../images/see-docs.png')} alt="" />
          <img src={require('../../images/Tnvgok.png')} alt="" />
          <img src={require('../../images/see-docs.png')} alt="" />
          <img src={require('../../images/Tnvgok.png')} alt="" />
        </div>
      </section>



<div className="about-section">
  <div className="inner-container">
    <h1>Let's build the future together.</h1>
    <p className="text2">
    	What really matters is the quality of the data, the data quotient of the organization, and the motives behind using data analytics.

    	We feel like we've come out of an energy of expectation that was burdensome and are finally getting into an exciting energy of projecting myself towards the realization of the company  we've dreamed about so much.
      
    </p>   
    <div className="skills">
      <span>BIGGER.</span>
      <span>BETTER.</span>
      <span>STRONGER.</span>
    </div>
  </div>
</div> <br /><br />


 <div className="team-heading">
      <h3>See-Docs Pricing Plan</h3>
  </div>
<section className="pricing">
 
		<div className="card-wrapper">
			<div className="card-header">
				<img src={require('../../images/starter.svg')} />
				<h2>STARTER</h2>
			</div>
			<div className="card-detail">
				<p><span className="fas fa-check check"></span> <b>1</b> full user</p>
				<p><span className="fas fa-check check"></span> <b>$</b> contact per client</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
			</div>
			<div className="card-price">
				<p><sup>$ </sup>500 <sub>/month</sub></p>
			</div>
			<button className="card-button">I WANT IT</button>
		</div>


		<div className="card-wrapper">
			<div className="card-header">
				<img src={require('../../images/professional.svg')} />
				<h2>PROFESSIONAL</h2>
			</div>
			<div className="card-detail">
				<p><span className="fas fa-check check"></span> <b>1</b> full user</p>
				<p><span className="fas fa-check check"></span> <b>$</b> contact per client</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
			</div>
			<div className="card-price">
				<p><sup>$ </sup>750 <sub>/month</sub></p>
			</div>
			<button className="card-button">I WANT IT</button>
		</div>


		<div className="card-wrapper">
			<div className="card-header">
				<img src={require('../../images/business.svg')} />
				<h2>BUSINESS</h2>
			</div>
			<div className="card-detail">
				<p><span className="fas fa-check check"></span> <b>1</b> full user</p>
				<p><span className="fas fa-check check"></span> <b>$</b> contact per client</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
				<p><span className="fas fa-times nocheck"></span> Lorem ipsum dolor</p>
			</div>
			<div className="card-price">
				<p><sup>$ </sup>1000 <sub>/month</sub></p>
			</div>
			<button className="card-button">I WANT IT</button>
		</div>

	</section>


   <div className="services" id="services">
      <h1>Stay HIPAA Compliant by Default.</h1>
      <div className="services__wrapper">
        <div className="services__card">
          <h2>See-Docs & Thenavigo</h2>
          <p>See-Docs & Thenavigo</p>
          <div className="services__btn"><button>Get Started</button></div>
        </div>
        <div className="services__card">
          <h2>Are you Ready?</h2>
          <p>See-Docs & Thenavigo</p>
          <div className="services__btn"><button>Get Started</button></div>
        </div>
        <div className="services__card">
          <h2>See-Docs & Thenavigo</h2>
          <p>See-Docs & Thenavigo</p>
          <div className="services__btn"><button>Get Started</button></div>
        </div>
        <div className="services__card">
          <h2>See-Docs & Thenavigo</h2>
          <p>See-Docs & Thenavigo</p>
          <div className="services__btn"><button>Get Started</button></div>
        </div>
      </div>
    </div>


  <div className="container09">
		<h1>Connect With Us</h1>
		<p>We would love to respond to your queries and help you 
		succeed. <br /> <br /> Feel free to get in touch with us.</p>

		<div className="contact-box">
			<div className="contact-left">
				<h3>Sent your request</h3>
				<form name="contactForm" onSubmit= {this.contactSubmit.bind(this)}>
					<div className="input-row">
						<div className="input-group">
							<label>Name</label>
							<input 
							 ref="fullname" 
							 type="text" 
							 onChange={this.handleChange.bind(this, "fullname")} 
							 value={this.state.fields["fullname"]} 
							 placeholder="See-Docs & Thenavigo" 
							/>
							<span style={{color: "red"}}>{this.state.errors["fullname"]}</span>
						</div>

						<div className="input-group">
							<label>Phone</label>
							<input
							 refs="phone" 
							 type="telephone" 
							 onChange={this.handleChange.bind(this, "phone")} 
							 value={this.state.fields["phone"]} 
							 placeholder="+237 658 11 29 64" 
							 />
							<span style={{color: "red"}}>{this.state.errors["phone"]}</span>
						</div>
					</div>

					<div className="input-row">
						<div className="input-group">
							<label>Email</label>
							<input
							 refs="email" 
							 type="email" 
							 onChange={this.handleChange.bind(this, "email")} 
							 value={this.state.fields["email"]} 
							 placeholder="contact@see-docs.com" 
							 />
							<span style={{color: "red"}}>{this.state.errors["email"]}</span>
						</div>

						<div className="input-group">
							<label>Subject</label>
							<input
							 refs="subject" 
							 type="text" onChange={this.handleChange.bind(this, "subject")} 
							 value={this.state.fields["subject"]} 
							 placeholder="Product Demo" 
							 />
							<span style={{color: "red"}}>{this.state.errors["subject"]}</span>
						</div>
					</div>

				<label>Message</label>
				<textarea
				 refs="message" 
				 onChange={this.handleChange.bind(this, "message")} 
				 value={this.state.fields["message"]} 
				 rows="5" 
				 placeholder="Your Message">
				 </textarea>
				<span style={{color: "red"}}>{this.state.errors["message"]}</span>
				<button type="submit">SEND</button>
				</form>
			</div>

			<div className="contact-right">
				<h3>Reach Us</h3>

				<table>
					<tbody>
					<tr>
						<td>Email</td>
						<td>contact@see-docs.com</td>
					</tr>
					<tr>
						<td>Phone</td>
						<td>+237 658 11 29 64</td>
					</tr>
					<tr>
						<td>Adress</td>
						<td>End Tar Mbangue, Douala, Cameroon</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>


<section className="section app" aria-label="app" id="app">
        <div className="container2">

          <figure className="app-banner">
            <img src={require('../../images/download-app.png')} style={{width:'608', height: '608'}} loading="lazy" alt="app banner" className="w-100" />
          </figure>

          <div className="app-content">

            <p className="section-subtitle">Download Our App</p>

            <h2 className="h2 section-title">Get The App For Free And Start Now</h2>

            <p className="section-text">
               We publish the beta version of our mobile platform as soon as possible.
              You can join the "WaitList" at the bottom of the page.
            </p>

            <ul className="btn-list">

              <li>
                <a href="#" className="btn-item-link" aria-label="Download from app store">
                  <img src={require('../../images/app-store.png')} style={{width:'185', height: '69', borderRadius: '10px'}} alt="app store" className="img" />
                </a>
              </li>

              <li>
                <a href="#" className="btn-item-link" aria-label="Download from play store">
                  <img src={require('../../images/play-store.png')} style={{width:'185', height: '69', borderRadius: '10px'}} alt="play store" className="img" />
                </a>
              </li>

            </ul>

          </div>

        </div>
      </section>

       <NotificationContainer/>
     <Footer />
    </Fragment>
    )
   }
}

export default Specialist;