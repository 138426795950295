import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/reset.css";
import './index.css';


import * as serviceWorker from './serviceWorker';
import App from './App';



ReactDOM.render(
        <App />,
  document.getElementById('root')
);

serviceWorker.register();