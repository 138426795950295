import React, { Fragment  } from 'react';
import Navheader from '../layout/Navheader';
import Footer from '../layout/Footer';

const Privacy = () => {
	return (
	<Fragment>
	<Navheader />
	
	<section className="hero-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="left-section">
                        <h1>
                            Experienced <span>mobile and web</span> applications and website builders measuring.
                        </h1>
                        <p>
                            See-Docs & Thenavigo, LLC is a team of experienced mobile and web applications and website builders measuring dozens of completed projects. We build and develop mobile applications for several top platforms, including Android  & IOS. 
                        </p>
                        <div className="d-flex">
                            <a href="#" className="contact-button">Contact Us</a>
                            <a href="https://www.freeprivacypolicy.com/live/6defc95f-83e4-4e2a-9798-1d7c68a4566f" target="_blank" rel="noopener noreferrer" className="view-more-button">Learn More</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <img src={require('../../images/d5.png')} className="w-100" alt="" />
                </div>
            </div>
        </div>
    </section>

	<Footer />
	</Fragment>
	)
}


export default Privacy;