import React, { Component, Fragment  } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
	render() {
		return (
	<Fragment>
	<footer>
    <div className="footer-container">
      <div className="footer-company-box">
        <a href="#" className="logo"><span>See-</span>Docs</a>
        <p>Welcome</p>
        <div className="footer-social">
          <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
          <a href="#"><i className="fa-brands fa-instagram"></i></a>
          <a href="#"><i className="fa-brands fa-youtube"></i></a>
          <a href="#"><i className="fa-brands fa-twitter"></i></a>
          <a href="#"><i className="fa-brands fa-linkedin"></i></a>
        </div>
      </div>

      <div className="footer-link-box">
        <strong>Company</strong>
        <ul>
          <li><a href="#">About</a></li>
          <li><a href="https://seedoc.substack.com/" target="_blank" rel="noopener noreferrer">Newsletter</a></li>
          <li><a href="#">Contact Sales</a></li>
          <li><a href="https://github.com/See-Docs/See-Docs_Handbook" target="_blank" rel="noopener noreferrer">See-Docs Handbook</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Podcast</a></li>
          <li><a href="#">Careers</a></li>
          <li><a href="#">Support</a></li>
        </ul>
      </div>

      <div className="footer-link-box">
        <strong>Product</strong>
        <ul>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Benchmarking</a></li>
          <li><a href="#">Integrations</a></li>
          <li><a href="#">Products</a></li>
          <li><Link to="/privacy">Privacy Policy</Link></li>
          <li><Link to="/terms">Terms Of Service</Link></li>
          <li><a href="#">Terms Of Sale</a></li>
          <li><a href="#">Status</a></li>
        </ul>
      </div>

      <div className="footer-link-box">
        <strong>Resource</strong>
        <ul>
          <li><a href="https://isaacpatrick183684.typeform.com/to/OQQMYLuo" target="_blank" rel="noopener noreferrer">Feedback</a></li>
          <li><a href="#">See-Docs R&D</a></li>
          <li><a href="#">Publications</a></li>
          <li><a href="#">Customer Case Studies</a></li>
          <li><Link to="/faq">FAQ</Link></li>
          <li><a href="#">Partners</a></li>
          <li><a href="#">Resource Hub</a></li>
        </ul>
      </div>

      <div className="footer-link-box">
        <strong>And More</strong>
        <ul>
          <li><a href="https://www.thenavigo.com/" target="_blank" rel="noopener noreferrer">Community</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Security</a></li>
          <li><a href="https://blog.thenavigo.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">See-Docs Mobile</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Tools</a></li>
          <li><a href="#" target="_blank" rel="noopener noreferrer">Technology</a></li>
          <li><a href="http://store.thenavigo.com/" target="_blank" rel="noopener noreferrer">Store </a></li>
        </ul>
      </div>
    </div>

    <div className="footer-bottom">
      <span>Design By @patrickbiyaga</span>
      <span> Copyright 2018 - 2024: See-Docs & Thenavigo, LLC. Stripe Atlas Founders, Delaware - USA </span>
    </div>
  </footer>

		</Fragment>

				)
			}
		}

export default Footer;



 