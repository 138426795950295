import React, { useRef, Fragment, useEffect } from 'react';

import ReactGA from 'react-ga';
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

import { Facebook, Twitter, Reddit } from 'react-sharingbuttons'
import 'react-sharingbuttons/dist/main.css'

import Navbar from './Navbar';
import Footer from './Footer';
import Testimonials from "./Testimonials";


const TRACKING_ID = "G-JNK9KH6N59";
ReactGA.initialize(TRACKING_ID);

function Landing() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
    // const form = useRef();
    const url = 'https://see-docs.com/'
    const shareText = 'Awesome Healthcare website Check this site!'

    const onHandleSubmit= (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ge1vhvs', 'template_lpir1uw', e.target, '9QrCBh06BRgq6s25x')
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon:'success',
          title:'“Thank You for Subscribing” 👍'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon:'error',
          title:"Something went wrong 🙃",
          text:error.text,
        })
      });
      e.target.reset();
  };

    return (
		<Fragment>
    

    <Navbar />
	   
    <section className="what-we-provide">
    <div className="w-info-box w-info-box1">
      <div className="w-info-icon">
        <i className="fa-solid fa-capsules"></i>
      </div>

      <div className="w-info-text">
        <strong>Specialised Service</strong>
        <p>Specialised Service</p>
      </div>
    </div>

    <div className="w-info-box w-info-box2">
      <div className="w-info-icon">
        <i className="fa-regular fa-comment"></i>
      </div>

      <div className="w-info-text">
        <strong>24/7 Advanced Care</strong>
        <p>24/7 Advanced Care</p>
      </div>
    </div>

    <div className="w-info-box w-info-box3">
      <div className="w-info-icon">
        <i className="fa-solid fa-square-poll-horizontal"></i>
      </div>

      <div className="w-info-text">
        <strong>Get Result Online</strong>
        <p>Get Result Online</p>
      </div>
    </div>
  </section>

  <section id="our-story">
    <div className="our-story-img">
      <img src={require('../../images/story_img.jpg')} alt="" />

      <a href="#" className="story-play-btn">
        <i className="fa-solid fa-play"></i>
        Play Video
      </a>
    </div>

    <div className="our-story-text">
      <h2>See-Docs is the best e-health platform thanks to its quality, professionalism and user-friendliness.</h2>

      <div className="story-number-container">
        <div className="story-number-box s-n-box1">
          <strong>1000+</strong>
          <span>Happy Patients</span>
        </div>

        <div className="story-number-box s-n-box2">
          <strong>150+</strong>
          <span>Expert Doctor's</span>
        </div>

        <div className="story-number-box s-n-box3">
          <strong>300+</strong>
          <span>Hospital Room's</span>
        </div>

        <div className="story-number-box s-n-box4">
          <strong>115+</strong>
          <span>Award Wining</span>
        </div>
      </div>
    </div>
  </section>



  <section id="our-services">
    <div className="services-heading">
      <div className="services-heading-text">
        <strong>Our Services</strong>
        <h2>High Quality Services For You.</h2>
      </div>
    </div>

    <div className="services-box-container">
      <div className="service-box s-box1">
        <i className="fa-solid fa-tooth"></i>
        <strong>Effective</strong>
        <p>By providing evidence-based health care services to those who need them.</p>
        <a href="">Read More</a>
      </div>

      <div className="service-box s-box2">
        <i className="fa-solid fa-hand-dots"></i>
        <strong>People-centred</strong>
        <p>By providing care that responds to individual preferences, 
        needs and values, within health services 
        that are organized around the needs of people.</p>
        <a href="">Read More</a>
      </div>

      <div className="service-box s-box3">
        <i className="fa-solid fa-heart-crack"></i>
        <strong>Integrated</strong>
        <p>By providing care that is coordinated across levels and providers 
        and makes available the full range of health services 
        throughout the life course.
        </p>
        <a href="">Read More</a>
      </div>
    </div>

    <div className="service-help-btn">
      <Facebook url={url} shareText={shareText} />
      <Twitter url={url} shareText={shareText} />
      <Reddit url={url} shareText={shareText} />
    </div>
  </section>

  <section id="why-choose-us">
    <div className="why-choose-us-left">
      <h3>Why Choose Us?</h3>

      <div className="why-choose-box-container">
        <div className="why-choose-box">
          <i className="fa-solid fa-check"></i>
          <div className="why-choose-box-text">
            <strong>Attention to Details</strong>
            <p>It’s our attention to the small stuff.</p>
          </div>
        </div>

        <div className="why-choose-box">
          <i className="fa-solid fa-check"></i>
          <div className="why-choose-box-text">
            <strong>Experts Only</strong>
            <p>We have specialists from various backgrounds. </p>
          </div>
        </div>

        <div className="why-choose-box">
          <i className="fa-solid fa-check"></i>
          <div className="why-choose-box-text">
            <strong>Pricing</strong>
            <p>Our prices are competitive and fair.</p>
          </div>
        </div>

        <div className="why-choose-box">
          <i className="fa-solid fa-check"></i>
          <div className="why-choose-box-text">
            <strong>A Plan for Success</strong>
            <p>You want results. </p>
          </div>
        </div>
      </div>
      <a href="#" className="why-choose-us-btn">Make Appointment</a>
    </div>
    <div className="why-choose-us-right">
      <h3>
        Emergency?<br/>
        Contact Us
      </h3>
    <p>Hello, How Can We Help You Today? 
    We have built a universal tool to address health care concerns around the world.</p>

      <div className="w-right-contact-container">
        
        <div className="w-right-contact-box">
          <i className="fa-solid fa-phone"></i>
          
          <div className="w-right-contact-box-text">
            <span> Call Us Now</span>
            <strong> Soon available </strong>
          </div>
        </div>

        <div className="w-right-contact-box">
          <i className="fa-solid fa-envelope"></i>
          
          <div className="w-right-contact-box-text">
            <span> Email Us </span>
            <strong>contact@see-docs.com</strong>
          </div>
        </div>

      </div>      
    </div>
  </section>

  <section id="team">
    
    <div className="team-heading">
      <h3>Our Leadership Team</h3>
      <p>Our Leadership Team</p>
    </div>

    <div className="s-team">
    <div className="team-box-container">
      <div className="team-box">
        <div className="team-img">
          <img src={require('../../images/d1.png')} alt="" />
        </div>

        <div className="team-text">
          <strong>Ing. Patrick BIYAGA</strong>
          <span>Owner / Co-Founder </span>
        </div>
      </div>
    </div>

    <div className="team-box-container">
      <div className="team-box">
        <div className="team-img">
          <img src={require('../../images/d2.png')} alt="" />
        </div>

        <div className="team-text">
          <strong>MMAMBA Ivan Christian </strong>
          <span>Co-Founder </span>
        </div>
      </div>
    </div> 

    <div className="team-box-container">
      <div className="team-box">
        <div className="team-img">
          <img src={require('../../images/d4.png')} alt="" />
        </div>

        <div className="team-text">
          <strong>Dr. Hélène BIKAT AYISSI </strong>
          <span>Co-Founder </span>
        </div>
      </div>
    </div>

    <div className="team-box-container">
      <div className="team-box">
        <div className="team-img">
          <img src={require('../../images/d3.png')} alt="" />
        </div>

        <div className="team-text">
          <strong> Why not join our team? </strong>
          <span>Board Member </span>
        </div>
      </div>
    </div>

    </div>


  </section>


  <section id="testimonials">
    <div className="testimonials-heading">
      <h3>Our Patients FeedBacks About Us.</h3>
      <Testimonials />
    </div>
  </section>

  <section id="subscribe">
    <h3>Subscribe For New Updates From See-Docs</h3>

    <form className="subscribe-box" onSubmit={onHandleSubmit}>
      <input type="email" name="user_email" placeholder="Example@site.com" required/>
      <button type='submit'>Join waitlist</button>
    </form>
  </section>

  <section id="blog">

        <div className="blog-heading">
            <strong className="services-heading-text">Recent Articles</strong>
            <h3>See-Docs News</h3>
        </div>

        
        <div className="blog-box-container">
        
            
            <div className="blog-box">
                
                <div className="blog-box-img">
                    <img alt="blog" src={require('../../images/yep.jpeg')} />
                    <a href="https://seedoc.substack.com/p/newsletter-january-22-featuresunday" target="_blank" rel="noopener noreferrer" className="blog-img-link">
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                </div>
                
                <div className="blog-box-text">
                    <strong>Health Care</strong>
                    <a href="https://seedoc.substack.com/p/newsletter-january-22-featuresunday" target="_blank" rel="noopener noreferrer">See-Docs & Thenavigo is going to exist</a>
                    <p>Remember our last newsletter just before Christmas in which we told you about our trials and adventures in moving forward with See-Docs & Thenavigo?</p>
                    <div className="blog-author">
                        <div className="blog-author-img">
                            <img alt="" src={require('../../images/yep.jpeg')} />
                        </div>
                        <div className="blog-author-text">
                            <strong>See-Docs</strong>
                            <span>Jan 12, 2023</span>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="blog-box">
                
                <div className="blog-box-img">
                    <img alt="blog" src={require('../../images/market1.jpg')} />
                    <a href="https://seedoc.substack.com/p/newsletter-november-07-there-is-one" target="_blank" className="blog-img-link" rel="noopener noreferrer">
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                </div>
                
                <div className="blog-box-text">
                    <strong>Health Care</strong>
                    <a href="https://seedoc.substack.com/p/newsletter-november-07-there-is-one" rel="noopener noreferrer" target="_blank">There is one habit that has changed our approach.</a>
                    <p>This surprised us a lot because it is the opposite of what we have been trying to do with See-Docs for several years!</p>
                    <div className="blog-author">
                        <div className="blog-author-img">
                            <img alt="" src={require('../../images/market1.jpg')} />
                        </div>
                        <div className="blog-author-text">
                            <strong>See-Docs</strong>
                            <span>Jan 12, 2023</span>
                        </div>
                    </div>
                </div>
            </div>


          
            <div className="blog-box">
                
                <div className="blog-box-img">
                    <img alt="blog" src={require('../../images/save.jpg')} />
                    <a href="https://seedoc.substack.com/p/newsletter-april-04-see-docs-is-art" target="_blank" className="blog-img-link" rel="noopener noreferrer">
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                </div>
                
                <div className="blog-box-text">
                    <strong>Health Care</strong>
                    <a href="https://seedoc.substack.com/p/newsletter-april-04-see-docs-is-art" rel="noopener noreferrer" target="_blank" >What you eat becomes what you create.</a>
                    <p>What you eat becomes what you create. That's the thought that drives us as we begin our week, as we move into our Phase 1 of realizations.</p>
                    <div className="blog-author">
                        <div className="blog-author-img">
                            <img alt="" src={require('../../images/save.jpg')} />
                        </div>
                        <div className="blog-author-text">
                            <strong>See-Docs</strong>
                            <span>Jan 12, 2023</span>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    
    </section>


  <section className="section app" aria-label="app" id="app">
        <div className="container2">

          <figure className="app-banner">
            <img src={require('../../images/download-app.png')} style={{width:'608', height: '608'}} loading="lazy" alt="app banner" className="w-100" />
          </figure>

          <div className="app-content">

            <p className="section-subtitle">Download Our App</p>

            <h2 className="h2 section-title">Get The App For Free And Start Now</h2>

            <p className="section-text">
              We publish the beta version of our mobile platform as soon as possible.
              You can join the "WaitList" at the bottom of the page.
            </p>

            <ul className="btn-list">

              <li>
                <a href="#" className="btn-item-link" aria-label="Download from app store">
                  <img src={require('../../images/app-store.png')} style={{width:'185', height: '69', borderRadius: '10px'}} alt="app store" className="img" />
                </a>
              </li>

              <li>
                <a href="#" className="btn-item-link" aria-label="Download from play store">
                  <img src={require('../../images/play-store.png')} style={{width:'185', height: '69', borderRadius: '10px'}} alt="play store" className="img" />
                </a>
              </li>

            </ul>

          </div>

        </div>
      </section>



      <Footer />
    
   
		</Fragment>

				)
		}

export default Landing;