import React, { Component, Fragment  } from 'react';
import { Link, Redirect } from 'react-router-dom';


import Navbar2 from './Navbar2';

class Doctor extends Component {
  render() {  
  return (
    <Fragment>
    <Navbar2 />
    <div className="appointment-search-container">
    <h3>Find Best HealthCare</h3>

    <div className="appointment-search">
      <div className="appo-search-box">
        <i className="fa-solid fa-magnifying-glass"></i>
        <input type="text" placeholder="Search Doctor Here" />
      </div>

      <div className="appo-search-box">
        <i className="fa-solid fa-location-dot"></i>
        <input type="text" placeholder="Set Your Location" />
      </div>

      <button>
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <section className="what-we-provide">
    <div className="w-info-box w-info-box1">
      <div className="w-info-icon">
        <i className="fa-solid fa-capsules"></i>
      </div>

      <div className="w-info-text">
        <strong>Specialised Service</strong>
        <p>Specialised Service</p>
      </div>
    </div>

    <div className="w-info-box w-info-box2">
      <div className="w-info-icon">
        <i className="fa-regular fa-comment"></i>
      </div>

      <div className="w-info-text">
        <strong>24/7 Advanced Care</strong>
        <p>24/7 Advanced Care</p>
      </div>
    </div>

    <div className="w-info-box w-info-box3">
      <div className="w-info-icon">
        <i className="fa-solid fa-square-poll-horizontal"></i>
      </div>

      <div className="w-info-text">
        <strong>Get Result Online</strong>
        <p>Get Result Online</p>
      </div>
    </div>
  </section>
    </Fragment>
    )
  }
}

export default Doctor;